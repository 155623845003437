import React, { useState } from 'react';
import logo from './logo.png';
import email from './email-received.png';
import tree from './rtdb-tree.png';
import approved from './waitlist-approved.png';
import './App.scss';

const ExamPart2 = () => {
  const [help0, setHelp0] = useState(0);
  const [help1, setHelp1] = useState(0);

  const helpBlock0 = '';
  return (
    <div className="exam exam--part part2">
      <h4>
        Part 2:{' '}
        <img
          className="icon"
          height="32"
          width="32"
          src="https://unpkg.com/simple-icons@latest/icons/github.svg"
        />{' '}
        Firebase Bookings Web App
      </h4>
      <p>
        In a branch named <b>fullstack</b>, you will build a simplified version
        of an internal tool we use.
        <br />
        Your goal will be to create a single page application that leverages{' '}
        <a target="_blank" href="https://firebase.google.com/">
          firebase
        </a>{' '}
        for users to manage simple slot reservations using a private link as
        authentication mechanism.
        <br />
        <br />
        <p className="block block--node">
          <div className="icon-svg"></div>
          <span>
            Even if the SDK is multi platform, you are required to demonstrate
            your backend skills using <b>NodeJS</b>
          </span>
        </p>
        <p className="use_case">
          Use case:{' '}
          <i>
            In the buildings where we have a silo (gym) there is usually
            multiples companies headquarters, we provide these companies with
            reserved days and times for their users to go in the space. But
            there can be only a limited amount of people in the space at the
            same time.
            <br />
            <br /> For the sake of the exam the company reservation will be:{' '}
            <br />
            <ul>
              <li>Mondays from 11:30 to 13:00 - 5 slots max</li>
              <li>Wednesdays from 07:30 to 09:00 - 5 slots max</li>
              <li>Friday from 11:30 to 13:00 - 5 slots max</li>
            </ul>
            <p>
              <br />
              Any day that does not satisfy the company reservations should not
              be shown in its calendar.{' '}
              <p>
                The number of slots is not fixed and might change it should not
                be hardcoded
              </p>
            </p>
          </i>
        </p>
      </p>
      <p>What you will be building:</p>
      <h5>Step 1: Request link</h5>
      Accessing the <b>root</b> of your SPA (ie: no additional URL parameters)
      should prompt the form to enter a work email.
      <br />
      <ul>
        <li> The user enters its company email and submits the form.</li>
        <li>
          {' '}
          At this points your application should receive the request (POST) in a{' '}
          <a target="_blank" href="https://firebase.google.com/docs/functions">
            Firebase Cloud Function
          </a>
        </li>
        <li>
          {' '}
          If this email domain name belongs to a company in your database it
          should create a private link for this user (no need for <b>JWT</b>) to
          access the web page
        </li>
        <li>
          {' '}
          This link should then be sent to the user email (using{' '}
          <a target="_blank" href="https://sendgrid.com">
            Sendgrid
          </a>{' '}
          or{' '}
          <a target="_blank" href="https://mailgun.com">
            Mailgun
          </a>{' '}
          API or any free email provider of your choice) with his private link
          to access the website URL for his company.
        </li>
      </ul>
      <p className="block block--node">
        <div className="icon-svg"></div>
        <span>
          You are required to use the RTDB (Real time database) of Firebase but
          you don't need to make the application realtime.
        </span>
      </p>
      <p className="block block--info">
        <div className="icon-svg"></div>
        <span>
          You do not have to implement Firebase authentication but you will need{' '}
          <a
            target="_blank"
            href="https://firebase.google.com/docs/auth/web/anonymous-auth"
          >
            anonymous login
          </a>{' '}
          to read Firebase RTDB of your project on the frontend.
        </span>
      </p>
      <p className="block block--info">
        <div className="icon-svg"></div>
        <span>
          On the backend you need to use{' '}
          <a
            target="_blank"
            href="https://firebase.google.com/docs/admin/setup"
          >
            Firebase admin sdk
          </a>{' '}
          to read and write the Firebase RTDB.
        </span>
      </p>
      <p className="block block--info">
        <div className="icon-svg"></div>
        <span>
          We recommend you leverage &nbsp;
          <a
            target="_blank"
            href="https://gmail.googleblog.com/2008/03/2-hidden-ways-to-get-more-from-your.html"
          >
            Gmail aliases
          </a>{' '}
          to handle multiple test accounts
        </span>
      </p>
      <a
        target="_blank"
        href="https://gyazo.com/4069e9979f809ed7fb22ade1db9b6b2a"
      >
        <video
          alt="Video from Gyazo"
          width="100%"
          muted
          loop
          playsInline
          controls
        >
          <source
            src="https://i.gyazo.com/4069e9979f809ed7fb22ade1db9b6b2a.mp4"
            type="video/mp4"
          />
        </video>
      </a>
      <h5>Step 2: Receiving the link in the mailbox</h5>
      <img className="exam-img" src={email} alt="Silofit" />
      <p>
        Clicking this link leads to step 3<br />
      </p>
      <h5>Step 3: Accessing and booking</h5>
      <p>
        When the web page opens and detects that the url is from a private link
        it should fetch the <b>bookings</b> for this company and{' '}
        <b>display one month</b> ahead of valid days to book according to the
        configuration for this company{' '}
      </p>
      <p>
        The user can now book a spot or remove himself from a spot. It does not
        matter which spot as long as there is room. If people booked before the
        user, he would see their email instead in the spots.
      </p>
      <a
        target="_blank"
        href="https://gyazo.com/0523dcc3f5e7fd5401d8458196270e75"
      >
        <video
          alt="Video from Gyazo"
          width="100%"
          muted
          loop
          playsInline
          controls
        >
          <source
            src="https://i.gyazo.com/0523dcc3f5e7fd5401d8458196270e75.mp4"
            type="video/mp4"
          />
        </video>
      </a>
      <h5>Step 4: The waitlist</h5>
      <p>
        When all the <b>5</b> spots have been booked for a day, the waitlist
        button shows up. The user can then register for the waitlist (FIFO)
      </p>
      <a
        target="_blank"
        href="https://gyazo.com/0785ad47d07de116ac15dc7001dea83e"
      >
        <video
          alt="Video from Gyazo"
          width="100%"
          muted
          loop
          playsInline
          controls
        >
          <source
            src="https://i.gyazo.com/0785ad47d07de116ac15dc7001dea83e.mp4"
            type="video/mp4"
          />
        </video>
      </a>
      <p>
        If someone that is booked for the day cancels the last person in the
        waitlist should take its place. Ex: Bob leaves the waitlist, and{' '}
        <b>eric+test</b> was in the waitlist, <b>eric+test</b> takes Bob spot.
      </p>
      <a
        target="_blank"
        href="https://gyazo.com/a039be77d192b652c0b6564e94782562"
      >
        <video
          alt="Video from Gyazo"
          width="100%"
          muted
          loop
          playsInline
          controls
        >
          <source
            src="https://i.gyazo.com/a039be77d192b652c0b6564e94782562.mp4"
            type="video/mp4"
          />
        </video>
      </a>
      <h5>Step 4: Admin view</h5>
      <p>
        <ul>
          <li>
            {' '}
            If the link is accessed by the person who is an admin (HR person at
            this company) that person can remove or add people{' '}
          </li>
          <li>
            {' '}
            If an admin cancels someone the waitlist acts normally by putting
            the next person in line
          </li>
          <li> The admin can enter its own email</li>
          <li>Clicking the waitlist as an admin does nothing</li>
        </ul>
        <p className="block block--info">
          <div className="icon-svg"></div>
          <span>
            Even as an admin, only an email that matches the company domain name
            can be added. You might want to use <b>gmail.com</b> as your company
            domain name.
          </span>
        </p>
      </p>
      <a
        target="_blank"
        href="https://gyazo.com/5a4104a05c517d8c57f3fc44104a8c29"
      >
        <video
          alt="Video from Gyazo"
          width="100%"
          muted
          loop
          playsInline
          controls
        >
          <source
            src="https://i.gyazo.com/5a4104a05c517d8c57f3fc44104a8c29.mp4"
            type="video/mp4"
          />
        </video>
      </a>
      <h5>Step 5: Access code visibility</h5>
      Finally you need to build a <b>Firebase cloud function</b> that takes as{' '}
      <b>GET</b> parameter a date (YYYYMMDD) and generates a random access code
      for this day if it's a valid day for the company and stores it in the
      database for this day.
      <p className="block block--info">
        <div className="icon-svg"></div>
        <span>
          Something along <b>/generateCodeForDay?date=20201231</b>.
        </span>
      </p>
      On the frontend if a code is set for the given day.
      <br />
      It should only be shown to the attendees OR admin.
      <br />
      <br />
      <a
        target="_blank"
        href="https://gyazo.com/7ac94a3da4ede94115df31fe1a1de749"
      >
        <video
          alt="Video from Gyazo"
          width="100%"
          muted
          loop
          playsInline
          controls
        >
          <source
            src="https://i.gyazo.com/7ac94a3da4ede94115df31fe1a1de749.mp4"
            type="video/mp4"
          />
        </video>
      </a>
      <h5>Step 6: Bonus</h5>
      <p className="block block--info">
        <div className="icon-svg"></div>
        <span>
          Real time: If a user A books or cancels, another user B with the page
          open would see it as well.
        </span>
      </p>
      <p className="block block--info">
        <div className="icon-svg"></div>
        <span>Unit testing of the cloud functions.</span>
      </p>
      <p className="block block--info">
        <div className="icon-svg"></div>
        <span>Typescript</span>
      </p>
    </div>
  );
};

const ExamPart1 = () => {
  return (
    <div className="exam--part part1">
      Welcome to the silofit fullstack exam!
      <hr />
      <br />
      This test will contain <b>2</b> parts.
      <br />
      📅 You have <b>one week </b> to complete this assignment.
      <br />
      <h4>Delivery</h4>
      Your answers must be pushed to your{' '}
      <img
        className="icon"
        height="32"
        width="32"
        alt="Github"
        src="https://unpkg.com/simple-icons@latest/icons/github.svg"
      />{' '}
      or{' '}
      <img
        className="icon"
        height="32"
        width="32"
        alt="Bitbucket"
        src="https://unpkg.com/simple-icons@latest/icons/bitbucket.svg"
      />
      into a new repository and upon completion you will send the link to this
      repository to <b>adrien@silofit.com</b> as well as your public URL for the
      Part 2 website you built.
      <p>
        So we can assess your git knowledge, each part must be submitted into
        its own <b>branch</b>
      </p>
      <h4>Part 1: Front end warmup</h4>
      <p>
        In a branch named <b>frontend</b> and using <b>ANY</b> framework or
        library you want (or vanilla js), please reproduce the following video
        in a web page:
      </p>
      <a
        target="_blank"
        href="https://gyazo.com/202805d13a84bc3bcb2913cc1b1c0dcd"
      >
        <video
          alt="Video from Gyazo"
          width="100%"
          muted
          loop
          playsInline
          controls
        >
          <source
            src="https://i.gyazo.com/202805d13a84bc3bcb2913cc1b1c0dcd.mp4"
            type="video/mp4"
          />
        </video>
      </a>
      <p>
        Basically, you start with 2 circles, and every time you hover on a
        circle, it splits in 4 circles of <b>random</b> colors{' '}
        <p className="block block--info">
          <div className="icon-svg"></div>
          Don't bother replicating the animations exactly you can do your own
          thing as long as the splitted behavior is respected 1 -> 4
        </p>
      </p>
    </div>
  );
};

function App() {
  const path = document.location.pathname;
  if (!path.match(/\/\w{3,}\-\w{3,}\-\w{3,}/)) return <span></span>;
  return (
    <div className="App">
      <div className="wrapper">
        <div className="logoWrapper">
          <img src={logo} alt="Silofit" />
        </div>
        <div className="exam">
          <ExamPart1 />
          <ExamPart2 />
        </div>
      </div>
    </div>
  );
}

export default App;
